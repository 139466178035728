import axios from 'axios';
import StatusCode from 'status-code-enum';

import type { IShare } from 'domains/Ad';
import type { TAdCondensed, TPaymentMethod, TProduct } from 'types/orderTypes';
import type { TPaymentCard } from 'types/orderTypes';
import { AdType } from 'domains/Ad';
import { CURRENCIES, Currency, IAdAttributeView } from 'types';
import type {
  IGetStripePaymentMethodsResponse,
  IOrderResponse,
  TCreatePaymentIntent,
  TGetOrder,
  TGetStripePaymentMethods,
  TRemoveStripePaymentMethod,
  TPayWithPayPal,
  TConfirmPhone,
  TPayByVoucher,
  TGetOrderIDFromAdID,
  TGetOrderIDFromAdIDPayload,
  TUpsell,
  UpsellPayload,
  TUpsellByName,
  UpsellByNamePayload,
  THistoryReport,
} from 'api/types/adwriteApiTypes';
import {
  TPayByPhone,
  TPayByPhoneData,
  TPayWithPayPalData,
  TPayByVoucherData,
} from 'api/types/adwriteApiTypes';
import type { TClearPendingPayment } from 'api/types/frontendApiTypes';
import { error, ok, Result } from 'domains/Result';
import {
  BUNDLE,
  ConfirmPhone,
  Order,
  OrderTracking,
  ORDER_ERROR_STATUS,
  ORDER_STATUS,
  PayPalRedirect,
  TOrderAd,
  TOrderBundle,
  TOrderPayment,
  TOrderStatus,
  TransactionProduct,
  GetOrder,
} from 'domains/Order';
import { formatPriceInCentsWithCurrency } from 'helpers/formatting';
import {
  CardPaymentMethod,
  CURRENCY_SYMBOL,
  Payment,
  PAYMENT_METHOD_TYPE,
  PaymentMethod,
  TCardType,
} from 'domains/Payment';
import { swapTestDomains } from 'utils/Urls';
import { ENVIRONMENT_NAME } from 'utils';
import { adWriteApi } from 'api/adWriteApi';
import { frontendApi } from 'api/frontendApi';
import { historyCheckApi } from 'api/historyCheckApi';
import { Message } from 'domains/Base';
import {
  isValidationError,
  ResponseError,
  ValidationError,
} from 'domains/Error';
import { IncomingHttpHeaders } from 'http';
import { formatAuthorizationHeader } from 'helpers/auth';
import { HistoryCheckPayload } from 'api/types/historyCheckApiTypes';

interface IOrderDataSource {
  getOrder: TGetOrder;
  getOrderIDFromAdID: TGetOrderIDFromAdID;
  clearPendingPayment: TClearPendingPayment;
  createPaymentIntent: TCreatePaymentIntent;
  getCardPaymentMethods: TGetStripePaymentMethods;
  removeCardPaymentMethod: TRemoveStripePaymentMethod;
  payWithPayPal: TPayWithPayPal;
  confirmPhone: TConfirmPhone;
  payByPhone: TPayByPhone;
  payByVoucher: TPayByVoucher;
  upsell: TUpsell;
  upsellByName: TUpsellByName;
  historyReport: THistoryReport;
}

interface IOrderRepository {
  getOrder: (
    id: string,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, Order>>;
  getOrderIDFromAdID: (
    payload: TGetOrderIDFromAdIDPayload,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, GetOrder>>;
  clearPendingPayment: () => Promise<Result<Error, Message>>;
  createPaymentIntent: (
    orderId: string,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, Payment>>;
  getCards: (
    userId: string,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, CardPaymentMethod[]>>;
  removeCard: (
    paymentMethodId: string,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, Message>>;
  payByPayPal: (
    orderId: string,
    data: TPayWithPayPalData,
    formToken: string,
    headers: IncomingHttpHeaders,
    url?: string,
  ) => Promise<Result<Error, PayPalRedirect>>;
  confirmPhone: (
    orderId: string,
    data: { network: string; phoneNumber: string },
    headers: IncomingHttpHeaders,
  ) => Promise<Result<ResponseError, ConfirmPhone>>;
  payByPhone: (
    orderId: string,
    data: TPayByPhoneData,
    formToken: string,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<ResponseError, Order>>;
  payByVoucher: (
    orderId: string,
    data: TPayByVoucherData,
    formToken: string,
    headers: IncomingHttpHeaders,
  ) => Promise<Result<Error, Order>>;
  upsell: (
    payload: UpsellPayload,
    formToken: string,
    headers?: IncomingHttpHeaders,
  ) => Promise<Result<Error, GetOrder>>;
  upsellByName: (
    payload: UpsellByNamePayload,
    formToken: string,
    userId: number,
    headers?: IncomingHttpHeaders,
  ) => Promise<Result<Error, GetOrder>>;
  historyReport: (
    payload: HistoryCheckPayload,
    headers?: IncomingHttpHeaders,
  ) => Promise<Result<Error, GetOrder>>;
}

function OrderRepository(OrderDataSource: IOrderDataSource): IOrderRepository {
  const getOrder: IOrderRepository['getOrder'] = async (id, headers) => {
    try {
      const response = await OrderDataSource.getOrder(id, headers);
      return ok(<Order>{
        id,
        ad: mapAd(response.data.ad),
        payment: mapPayment(response.data),
        status: ORDER_STATUS.PAYMENT_REQUIRED,
        bundle: null,
        formToken: response.headers.formtoken ?? '',
        tracking: null,
      });
    } catch (err: any) {
      // TODO: strictly type err
      const isReview =
        axios.isAxiosError(err) &&
        err.response?.status === StatusCode.ClientErrorNotAcceptable;
      const isForbidden =
        axios.isAxiosError(err) &&
        err.response?.status === StatusCode.ClientErrorForbidden;
      const isPublish =
        axios.isAxiosError(err) &&
        err.response?.status === StatusCode.ClientErrorGone;
      const isErrorNative = err instanceof Error;
      if (isForbidden) {
        return error(new Error(ORDER_ERROR_STATUS.FORBIDDEN));
      } else if (isReview) {
        return ok(generateReviewOrder(id));
      } else if (isPublish) {
        const data = err?.response?.data as IOrderResponse;
        return ok(generatePublishedOrder(id, data));
      } else if (isErrorNative) {
        return error(err);
      } else {
        return error(new Error(err));
      }
    }
  };

  const getOrderIDFromAdID: IOrderRepository['getOrderIDFromAdID'] = async (
    payload,
    headers,
  ) => {
    try {
      const response = await OrderDataSource.getOrderIDFromAdID(
        payload,
        headers,
      );
      const orderID = response?.data?.id;
      if (orderID) return ok({ id: orderID });
      else return error(new Error('No Order ID'));
    } catch (err) {
      return error(new Error(err));
    }
  };

  const clearPendingPayment: IOrderRepository['clearPendingPayment'] =
    async () => {
      try {
        const response = await OrderDataSource.clearPendingPayment();
        return ok({ message: response.data.message });
      } catch (err) {
        return error(new Error(err));
      }
    };

  const createPaymentIntent: IOrderRepository['createPaymentIntent'] = async (
    orderId,
    headers,
  ) => {
    try {
      const { data } = await OrderDataSource.createPaymentIntent(
        {
          order_id: orderId,
        },
        headers,
      );
      return ok({
        clientSecret: data.clientSecret,
      });
    } catch (err: any) {
      // Todo create a generic error response and the map
      if (axios.isAxiosError(err)) {
        return error(new Error(`${err.code}: ${err.message}`));
      } else if (err instanceof Error) {
        return error(err);
      } else {
        return error(new Error(err));
      }
    }
  };

  const getCards: IOrderRepository['getCards'] = async (userId, headers) => {
    try {
      const { data } = await OrderDataSource.getCardPaymentMethods(
        userId,
        headers,
      );
      const cards = mapCardPaymentMethods(data);
      return ok(cards);
    } catch (err) {
      return error(new Error(err));
    }
  };

  const confirmPhone: IOrderRepository['confirmPhone'] = async (
    orderId,
    data,
    headers,
  ) => {
    try {
      const response = await OrderDataSource.confirmPhone(
        orderId,
        data,
        headers,
      );
      return ok({ id: response.data.phonePaymentLogId });
    } catch (err) {
      if (isValidationError(err.response?.status as number)) {
        return error(<ResponseError>{
          type: 'VALIDATION',
          error: err.response?.data as ValidationError,
        });
      } else {
        return error({ type: 'UNKNOWN', error: new Error(err) });
      }
    }
  };

  const payByPhone: IOrderRepository['payByPhone'] = async (
    orderId,
    data,
    formToken,
    headers,
  ) => {
    try {
      const { data: result } = await OrderDataSource.payByPhone(
        orderId,
        data,
        formToken,
        headers,
      );
      return ok(generatePublishedOrder(orderId, result));
    } catch (err) {
      if (isValidationError(err.response?.status as number)) {
        return error(<ResponseError>{
          type: 'VALIDATION',
          error: err.response?.data as ValidationError,
        });
      } else {
        return error({
          type: 'UNKNOWN',
          error: new Error(err),
        });
      }
    }
  };

  const removeCard: IOrderRepository['removeCard'] = async (
    paymentMethodId,
  ) => {
    try {
      await OrderDataSource.removeCardPaymentMethod(paymentMethodId);
      return ok({ message: 'Card removed successfully' });
    } catch (err) {
      return error(new Error(err));
    }
  };

  const payByPayPal: IOrderRepository['payByPayPal'] = async (
    orderId,
    data,
    formToken,
    headers,
  ) => {
    try {
      const { data: result } = await OrderDataSource.payWithPayPal(
        `/ddapi/legacy/adwrite/api/v3/payments/paypal/checkout/order/${orderId}`,
        data,
        formToken,
        headers,
      );
      return ok(result);
    } catch (err) {
      return error(new Error(err));
    }
  };

  const payByVoucher: IOrderRepository['payByVoucher'] = async (
    orderId,
    data,
    formToken,
    headers,
  ) => {
    try {
      const { data: result } = await OrderDataSource.payByVoucher(
        orderId,
        data,
        formToken,
        headers,
      );
      return ok(generatePublishedOrder(orderId, result));
    } catch (err) {
      return error(new Error(err));
    }
  };

  const upsell: IOrderRepository['upsell'] = async (
    payload,
    formToken,
    headers,
  ) => {
    try {
      const response = await OrderDataSource.upsell(payload, {
        ...headers,
        ...formatAuthorizationHeader(formToken),
      });
      const orderID = response.data?.id;
      if (orderID) return ok({ id: orderID });
      else return error(new Error('No Order ID'));
    } catch (err) {
      return error(new Error(err));
    }
  };

  const upsellByName: IOrderRepository['upsellByName'] = async (
    payload,
    formToken,
    userId,
    headers,
  ) => {
    try {
      const response = await OrderDataSource.upsellByName(payload, {
        ...headers,
        ...formatAuthorizationHeader(formToken),
        'X-Identity-UserId': userId.toString(),
      });
      const orderID = response.data;
      if (orderID) return ok(<GetOrder>{ id: orderID.toString() });
      else return error(new Error(`Expected orderID, received ${orderID}`));
    } catch (err: any) {
      return error(new Error(err));
    }
  };

  const historyReport: IOrderRepository['historyReport'] = async (
    payload,
    headers,
  ) => {
    try {
      const response = await OrderDataSource.historyReport(payload, {
        ...headers,
      });
      const orderID = response.data?.id;
      if (orderID) return ok({ id: orderID });
      else return error(new Error('No Order ID'));
    } catch (error) {
      return error(new Error(error));
    }
  };

  return {
    getOrder,
    getOrderIDFromAdID,
    clearPendingPayment,
    createPaymentIntent,
    getCards,
    removeCard,
    payByPayPal,
    confirmPhone,
    payByPhone,
    payByVoucher,
    upsell,
    upsellByName,
    historyReport,
  };
}

const generateReviewOrder = (id: string): Order => {
  return {
    id,
    ad: null,
    payment: null,
    status: ORDER_STATUS.IN_REVIEW,
    bundle: null,
    formToken: '',
    tracking: null,
  };
};

const generatePublishedOrder = (id: string, data: IOrderResponse): Order => {
  const {
    ad,
    completionPage,
    email,
    currency,
    price,
    orderNo,
    paymentMethod,
    report,
    products,
    amount,
  } = data;
  const { bundle, bundlePrice } = mapBundlesForTracking(currency, products);

  const status = mapOrderStatus(completionPage);

  return {
    id,
    ad: mapAd(ad),
    payment: null,
    status,
    bundle: mapBundle(completionPage),
    // crossPlatform: mapCrossPlatform(products),
    crossPlatform: false, // currently not supported
    historyCheck: {
      email: email ?? '',
      report: report ?? '',
      orderNo: orderNo ?? 0,
      paymentMethod: paymentMethod ?? '',
      price: mapPrice(price, currency),
    },
    formToken: '',
    tracking: mapOrderTracking(
      id,
      status,
      bundle,
      bundlePrice,
      ad,
      orderNo,
      price,
      products,
      amount,
    ),
  };
};

const mapBundlesForTracking = (currency: Currency, products?: TProduct[]) => {
  if (products === undefined) {
    return {
      bundle: null,
      bundlePrice: null,
    };
  } else {
    const [product] = products;
    const bundle = product.name;
    const bundlePrice = isEuroOrSterlingPrice(
      currency,
      product.price,
      product.sterlingPrice,
    );

    return {
      bundle,
      bundlePrice,
    };
  }
};

const mapPrice = (price?: string, currency?: Currency) =>
  `${mapCurrency(currency)}${price}`;

const mapCurrency = (currency?: Currency): CURRENCY_SYMBOL =>
  currency === CURRENCIES.GBP ? CURRENCY_SYMBOL.GBP : CURRENCY_SYMBOL.EUR;

const isEuroOrSterlingPrice = (
  currency: Currency,
  euroPrice: number,
  sterlingPrice: number,
) => {
  return currency === CURRENCIES.EUR ? euroPrice : sterlingPrice;
};

const mapPaymentDisplay = (
  type: PAYMENT_METHOD_TYPE,
  displayName: string,
  savedCard?: TPaymentCard,
): string => {
  switch (type) {
    case 'STRIPE':
      return displayName;
    case PAYMENT_METHOD_TYPE.SAVED_CARD_STRIPE:
      return mapCardDisplay(savedCard!.type, savedCard!.display);
    case PAYMENT_METHOD_TYPE.PAYPAL:
      return 'Pay by PayPal';
    case PAYMENT_METHOD_TYPE.SAVED_PAYPAL:
      return 'Saved PayPal';
    case PAYMENT_METHOD_TYPE.MOBILE:
      return 'Pay by Phone';
    case PAYMENT_METHOD_TYPE.SAVED_MOBILE:
      return `Phone: ${savedCard?.display}`;
    case PAYMENT_METHOD_TYPE.VOUCHER:
      return 'Use Voucher';
    default:
      return '';
  }
};

export const mapPaymentIcon = (cardType?: string): string | null => {
  switch (cardType) {
    case 'MASTERCARD':
      return '/images/logos/mastercard-logo.svg';
    case 'AMEX':
      return '/images/logos/amex-logo.svg';
    case 'VISA':
      return '/images/logos/visa-logo.svg';
    default:
      return null;
  }
};

const mapCardLastFourDigits = (display: string) => {
  return display.substring(display.length - 4);
};

const mapPayment = (orderResponse: IOrderResponse): TOrderPayment => {
  return {
    methods: orderResponse.paymentMethods.reduce<PaymentMethod[]>(
      (payments, payment) => {
        let availableNetworks = payment.availableNetworks;

        if (!payment.display) {
          return payments;
        }
        if (payment.type === 'SAVED_MOBILE') {
          // Saved Mobile doesn't contain the available networks but regular
          // mobile payment does, so we need to swap them
          const mobilePayment = orderResponse.paymentMethods.find(
            (item) => item.type === 'MOBILE',
          );
          if (mobilePayment) {
            availableNetworks = mobilePayment.availableNetworks;
          }
        }
        payments.push({
          id: payment.stripePaymentMethodId,
          name: payment.displayName,
          url: payment.url
            ? swapTestDomains(payment.url, ENVIRONMENT_NAME)
            : '',
          type: payment.type as PAYMENT_METHOD_TYPE,
          selected: payment.defaultSelection,
          display: mapPaymentDisplay(
            payment.type as PAYMENT_METHOD_TYPE,
            payment.displayName,
            payment.savedCard,
          ),
          icon: mapPaymentIcon(payment.savedCard?.type),
          surcharge: formatPriceInCentsWithCurrency(payment.surchargeAmount),
          availableNetworks: availableNetworks ?? null,
        });
        return payments;
      },
      [],
    ),
    products: orderResponse.lineItems[0].lineItemProducts.map(
      (productItem) => ({
        name: productItem.name,
        description:
          productItem.duration === 0 ? '' : `for ${productItem.duration} days`,
        price: formatPriceInCentsWithCurrency(
          productItem.price,
          productItem.sterlingPrice,
          orderResponse.currency,
        ),
      }),
    ),
    displayPrice: formatPriceInCentsWithCurrency(
      orderResponse.total,
      orderResponse.sterlingTotal,
      orderResponse.currency,
    ),
    total:
      orderResponse.currency == 'EUR'
        ? orderResponse.total
        : orderResponse.sterlingTotal,
    currency: orderResponse.currency,
    defaultCard: mapDefaultCard(orderResponse.paymentMethods),
  };
};

const mapDefaultCard = (
  paymentMethods: TPaymentMethod[],
): CardPaymentMethod[] => {
  const savedCard = paymentMethods.filter(
    ({ type }) => type === PAYMENT_METHOD_TYPE.SAVED_CARD_STRIPE,
  )[0];
  if (savedCard && savedCard.savedCard) {
    return [
      {
        type: mapCardType(savedCard.savedCard.type),
        paymentMethodId: savedCard.stripePaymentMethodId,
        displayCardNumber: mapCardDisplay(
          savedCard.savedCard.type,
          savedCard.savedCard.display,
        ),
        lastFourDigits: mapCardLastFourDigits(savedCard.savedCard.display),
        isDefault: true,
        icon: mapPaymentIcon(mapCardType(savedCard.savedCard.type)),
      },
    ];
  } else {
    return [];
  }
};

const mapAdType = (
  wanted: boolean,
  forSale: boolean,
  c2b: boolean,
): AdType[] => {
  const adTypes: AdType[] = [];
  if (wanted) adTypes.push(AdType.WANTED);
  if (forSale) adTypes.push(AdType.FOR_SALE);
  if (c2b) adTypes.push(AdType.C2B);
  return adTypes;
};

const mapAd = (ad?: TAdCondensed): TOrderAd =>
  ad
    ? {
        id: ad.id.toString(),
        friendlyUrl: ad.friendlyUrl ?? null,
        share: mapShareOptions(ad.friendlyUrl),
        adType: mapAdType(ad.wantedAd, ad.forSaleAd, ad.c2BAd),
      }
    : null;

export const mapShareOptions: (friendlyUrl?: string) => IShare = (
  friendlyUrl,
) => {
  const shareMessage = 'Check out this ad on DoneDeal';
  return {
    whatsapp: {
      url: `https://wa.me/?text=${shareMessage} ${friendlyUrl}&utm_source=whatsapp&utm_medium=social`,
    },
    facebook: {
      url: `https://www.facebook.com/sharer/sharer.php?quote=${shareMessage}&u=${friendlyUrl}&utm_source=facebook&utm_medium=social`,
    },
    twitter: {
      url: `https://twitter.com/intent/tweet?url=${friendlyUrl}&text=${shareMessage}&hashtags=donedeal`,
    },
    email: {
      url: `mailto:?subject=${shareMessage}&body=${shareMessage} ${friendlyUrl}&utm_source=email&utm_medium=social`,
    },
  };
};

const mapOrderStatus = (completionPage?: string): TOrderStatus => {
  switch (completionPage) {
    case 'BASIC':
      return ORDER_STATUS.PUBLISH;
    case 'PAYMENT_REQUIRED':
      return ORDER_STATUS.PAYMENT_REQUIRED;
    case 'VEHICLE_REPORT':
      return ORDER_STATUS.VEHICLE_REPORT;
    case 'BUMP':
      return ORDER_STATUS.BUMP;
    case 'SUPERBOOSTER':
      return ORDER_STATUS.SUPERBOOSTER;
    default:
      return ORDER_STATUS.PUBLISH;
  }
};

const mapBundle = (completionPage?: string): TOrderBundle => {
  switch (completionPage) {
    case 'BASIC':
      return BUNDLE.BASIC;
    case 'AUTOBUMP':
      return BUNDLE.AUTOBUMP;
    case 'SPOTLIGHT':
      return BUNDLE.SPOTLIGHT;
    default:
      return BUNDLE.BASIC;
  }
};

// Currently not supported
// const mapCrossPlatform = (products?: TProduct[]) => {
//   if (products && products.length > 0) {
//     return products.some(({ name }) => name === 'Adverts.ie autolist');
//   }
//   return false;
// };

const mapCardType = (type: string): TCardType => {
  switch (type) {
    case 'AMEX':
      return 'AMEX';
    case 'MASTERCARD':
      return 'MASTERCARD';
    case 'VISA':
    default:
      return 'VISA';
  }
};

const mapCardDisplay = (type: string, display: string): string => {
  const firstTwelveDigits = 'xxxxxxxxxxxx';
  switch (type) {
    case 'MASTERCARD':
      return display.replace(firstTwelveDigits, 'Mastercard ');
    case 'AMEX':
      return display.replace(firstTwelveDigits, 'Amex ');
    case 'VISA':
    default:
      return display.replace(firstTwelveDigits, 'VISA ');
  }
};

const mapCardPaymentMethods = (
  paymentMethods: IGetStripePaymentMethodsResponse[],
): CardPaymentMethod[] => {
  return paymentMethods.map((pm) => {
    return {
      displayCardNumber: mapCardDisplay(pm.type, pm.display),
      lastFourDigits: mapCardLastFourDigits(pm.display),
      paymentMethodId: pm.paymentMethodId,
      type: mapCardType(pm.type),
      icon: mapPaymentIcon(mapCardType(pm.type)),
      isDefault: false,
    };
  });
};

const mapOrderTracking = (
  orderId: string,
  status: TOrderStatus,
  bundle: string | null,
  bundlePrice: number | null,
  ad?: TAdCondensed,
  orderNumber?: number,
  price?: string,
  products?: TProduct[],
  amount?: string,
): OrderTracking => {
  const makeModelYear = mapTrackingMakeModelYear(ad);
  const sellerType = mapTrackingAdSeller(ad);
  const paymentMethod = getPaymentMethod();
  const transactionProducts = mapProducts(products);
  const reportId = orderNumber ? ([orderNumber.toString()] as [string]) : null;
  const reportTotal = price ? Number(price) : null; // value provided in euro
  const adTotal = amount ? Number(amount) / 100 : null; // value provided in cents, Google expects them in real prices.
  const transactionId =
    status === ORDER_STATUS.VEHICLE_REPORT ? reportId : ([orderId] as [string]);
  const transactionTotal =
    status === ORDER_STATUS.VEHICLE_REPORT ? reportTotal : adTotal;
  return {
    adId: ad?.id ? [ad.id.toString()] : null,
    adSection: ad?.section.name ? [ad.section.name] : null,
    price: ad?.price ? [ad.price] : [''],
    county: ad?.county ? [ad.county] : [''],
    deliveryAvailable: ad?.deliveryAvailable ? [ad.deliveryAvailable] : [false],
    make: makeModelYear?.make || null,
    model: makeModelYear?.model || null,
    year: makeModelYear?.year || null,
    dealerId: sellerType?.dealerId || null,
    sellerId: sellerType?.sellerId || null,
    sellerType: sellerType?.sellerType || [''],
    paymentMethod,
    transactionId,
    transactionTotal,
    transactionProducts,
    bundle: bundle ? [bundle] : null,
    bundlePrice: typeof bundlePrice === 'number' ? [bundlePrice] : null,
  };
};

const mapProducts = (products?: TProduct[]): TransactionProduct[] | null => {
  if (products) {
    return products.map((product) => {
      return {
        sku: product.id,
        name: product.name,
        price: Number(product.price) / 100,
        quantity: 1,
      };
    });
  } else return null;
};

const getValueFromAdAttribute = (
  attributes: IAdAttributeView[],
  name: string,
) => {
  const attribute = attributes.find((attribute) => attribute.name === name);
  return attribute?.value || '';
};

const mapTrackingMakeModelYear = (ad?: TAdCondensed) => {
  if (ad && ad.displayAttributes) {
    const make = getValueFromAdAttribute(ad.displayAttributes, 'make');
    const model = getValueFromAdAttribute(ad.displayAttributes, 'model');
    const year = getValueFromAdAttribute(ad.displayAttributes, 'year');
    return {
      make: [make] as [string],
      model: [model] as [string],
      year: [year] as [string],
    };
  }
};

const mapTrackingAdSeller = (ad?: TAdCondensed) => {
  if (ad && ad.dealer) {
    return {
      dealerId: [ad.dealer.id.toString()] as [string],
      sellerType: ['Pro'] as [string],
    };
  } else if (ad && ad.seller) {
    if (ad.seller.traderName) {
      return {
        sellerId: [ad.seller.id.toString()] as [string],
        sellerType: ['Trader'] as [string],
      };
    } else {
      return {
        sellerId: [ad.seller.id.toString()] as [string],
        sellerType: ['Private'] as [string],
      };
    }
  }
};

const getPaymentMethod = () => {
  if (typeof window !== 'undefined') {
    const paymentMethod = window.localStorage.getItem('paymentMethod');
    return paymentMethod ? ([paymentMethod] as [string]) : null;
  } else return null;
};

export { OrderRepository };

export const orderRepository = OrderRepository({
  getOrder: adWriteApi.getOrder, // Todo rename get
  getOrderIDFromAdID: adWriteApi.getOrderIDFromAdID,
  clearPendingPayment: frontendApi.clearPendingPayment,
  createPaymentIntent: adWriteApi.createPaymentIntent,
  getCardPaymentMethods: adWriteApi.getStripePaymentMethods,
  removeCardPaymentMethod: adWriteApi.removeStripePaymentMethod,
  payWithPayPal: adWriteApi.payWithPayPal,
  confirmPhone: adWriteApi.confirmPhone,
  payByPhone: adWriteApi.payByPhone,
  payByVoucher: adWriteApi.payByVoucher,
  upsell: adWriteApi.upsell,
  upsellByName: adWriteApi.upsellByName,
  historyReport: historyCheckApi.getHistoryCheck,
});
